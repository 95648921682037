import React from 'react';
import ClickBankRedirect from '../components/ClickBankRedirect';

const KetoMealPlan = () => {
  return (
    <ClickBankRedirect url='https://www.digistore24.com/redir/283755/DrWhizBang/' />
  );
};

export default KetoMealPlan;
