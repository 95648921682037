import React from 'react';

const UnderConstructionScreen = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>BAD ENGLISH</h1>
        <h3>A Software Company</h3>
        <h6>THIS PAGE IS UNDER CONSTRUCTION</h6>
      </header>
    </div>
  );
};

export default UnderConstructionScreen;
