import React, { useEffect } from 'react';

const sleep = (t) => new Promise((s) => setTimeout(s, t));

const ClickBankRedirect = ({ url }) => {
  useEffect(() => {
    (async () => {
      await sleep(Math.round(Math.random() * 1000));
      window.location.replace(url);
    })();
  });

  return (
    <div id='loading-wrapper'>
      <div id='loading-text'>LOADING</div>
      <div id='loading-content'></div>
    </div>
  );
};

export default ClickBankRedirect;
