import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClickBankRedirect from "./components/ClickBankRedirect";
import KetoMealPlan from "./screens/KetoMealPlan";

// Screens
import UnderConstructionScreen from "./screens/UnderConstructionScreen";

// Try to install these extensions first
// https://marketplace.visualstudio.com/items?itemName=dsznajder.es7-react-js-snippets
// https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag
// https://marketplace.visualstudio.com/items?itemName=xabikos.JavaScriptSnippets
// https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode
// You can install these at another time, but they help
// https://marketplace.visualstudio.com/items?itemName=CoenraadS.bracket-pair-colorizer
// https://marketplace.visualstudio.com/items?itemName=streetsidesoftware.code-spell-checker

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Brendan's Routes  */}
        <Route path="/" element={<UnderConstructionScreen />} />
        <Route path="/KetoMealPlan" element={<KetoMealPlan />} />
        <Route
          path="/keto-book-one"
          element={
            <ClickBankRedirect url="https://www.digistore24.com/redir/269221/DrWhizBang/" />
          }
        />
        <Route
          path="/keto-meal-plan"
          element={
            <ClickBankRedirect url="https://www.digistore24.com/redir/283755/DrWhizBang/" />
          }
        />
        <Route
          path="/GlucaFix"
          element={
            <ClickBankRedirect url="https://www.digistore24.com/redir/331041/DrWhizBang/" />
          }
        />

        {/* Keishawnna's Routes's Routes  */}
        {/* 
        Step 1: Right Click on the "screens" folder and a new file like SomeFileName.js
        Step 2: in that folder type in "rafce" and press tab.
        Step 3: Add the file to this page at the top & add the route.
        */}
      </Routes>
    </Router>
  );
};

export default App;
